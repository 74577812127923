/* global M */
/* global monstecLib */
import i18next from "../localisation.js";


export default class ExpertChatsView {
    constructor() {
        this.SECTION_SELECTOR = "#quack-overview";

        this.chatService = monstecLib.produckContext.chatService;
        this.utils = monstecLib.produckContext.utils;

        this.log = new monstecLib.Log(1);
    }

    async attachTo(rootElementId) {
        let instance = this;
        instance.containerId = rootElementId;
        instance.chatsView = $("#" + rootElementId);

        instance.initQuacksOverview();
    }

    /**
     * Initialises the main control elements of the expert-chats-view.
     */
    async initQuacksOverview() {
        const instance = this;

        //switch chat active status
        $("#saveQuackMode").on("click", function () {
            var quackMode = $("#quack-target-select").val();
            var checkedQuacks = $(".quack-state-input:checkbox:checked");

            if (checkedQuacks.length === 0 && quackMode !== null) {
                instance.utils.createSimpleAlertModal(
                    i18next.t("text.selectChat")
                );
                return;
            } else if (quackMode === null && checkedQuacks.length > 0) {
                instance.utils.createSimpleAlertModal(
                    i18next.t("text.selectAction")
                );
                return;
            } else if (quackMode === null && checkedQuacks.length === 0) {
                instance.utils.createSimpleAlertModal(
                    i18next.t("text.selectChatNAction")
                );
                return;
            } else {
                let quackPromises = [];
                let promise;
                let toast;

                if (quackMode === "PRODUCK" || quackMode === "PRIVATE") {
                    checkedQuacks.each(function () {
                        var quackId = $(this)
                            .closest(".collapsible-header")
                            .data("chatid");

                        let params = { id: quackId, quackMode: quackMode };
                        // note chatQuacks have no dedicated outsource endpoint like articles, so changed params are send via general save / update chat endpoint
                        promise = instance.chatService.updateChat(params);                        
                        quackPromises.push(promise);                
                    });
                    if (quackPromises.length > 1) {
                        toast = quackMode === "PRODUCK" ? {html: i18next.t("text.quacks_activated")} : {html: i18next.t("text.quack_deactivated")};
                    } else {
                        toast = quackMode === "PRODUCK" ? {html: i18next.t("text.quack_activated")} : {html: i18next.t("text.quack_deactivated")};
                    }
                    resolvePromiseAll();

                } else if (quackMode === "EXTERNAL") {

                    function getQuackMap() {
                        let quackMap = {};
                        checkedQuacks.each(function () {
                            var quackId = $(this).closest(".collapsible-header").data("chatid");                    
                            var quackTitle = $("#quack-header-" + quackId).find('.chat-topic').text();
                    
                            let quack = {
                                id: quackId,
                                title: quackTitle,
                            };
                    
                            quackMap[quackId] = quack;
                        });
                        return quackMap;
                    }

                    function createDomainSelectionModal(tokenMap, itemMap) {

                        let optionOne = i18next.t("general.save"),
                            optionTwo = i18next.t("general.cancel");

                        let selectDropdown = "";

                        let selected =
                            tokenMap.length == 0 ? 'selected="selected"' : "";

                        tokenMap.forEach((item) => {
                            selectDropdown +=
                                '<option value="' +
                                item.domain +
                                '"' +
                                selected +
                                ">" +
                                item.domain +
                                "</option>";
                        });

                        let quackSelectModule = "";
                        Object.entries(itemMap).forEach(([key, item], index) => {
                            quackSelectModule +=
                                "<p>" +
                                i18next.t(
                                    "articlesection.choose_target_site_for",
                                    { title: item.title }
                                ) +
                                "</p>" +
                                '<div class="input-field domain-select-wrapper" data-quack-id="' +
                                item.id +
                                '">' +
                                '<select id="selectDomain_' +
                                index +
                                '">' +
                                selectDropdown +
                                "</select>" +
                                "</div>";
                        });

                        const modal =
                            '<div id="domainSelectionModalChat" class="modal dynamic-modal">' +
                            '<div class="modal-content">' +
                            quackSelectModule +
                            "</div>" +
                            '  <div class="modal-footer">' +
                            '    <a class="option-one modal-close waves-effect waves-teal btn-flat" tabindex="1">' +
                            optionOne +
                            "</a>" +
                            '    <a class="option-two modal-close waves-effect waves-teal btn-flat" tabindex="2">' +
                            optionTwo +
                            "</a>" +
                            "  </div>" +
                            "</div>";

                        $("body").append(modal);

                        var elems = $("#domainSelectionModalChat").find("select");
                        M.FormSelect.init(elems);

                        $("#domainSelectionModalChat").modal({
                            dismissible: false,
                            onCloseEnd: instance.utils._removeUnusedModalLayers,
                        });

                        $("#domainSelectionModalChat").modal("open");
                    }

                    //get all tokens and domains for user
                    instance.chatService
                        .getQuackTokensAndDomains()
                        .then(function (tokenMap) {
                            console.log(
                                "Fetching token successful: ",
                                tokenMap
                            );
                            let quackObj = getQuackMap();
                                createDomainSelectionModal(
                                    tokenMap,
                                    quackObj
                                );
                                quackPromisesInit(quackObj);
                        })
                        .catch(function (error) {
                            console.log("Quack token service error: ", error);
                            instance.utils.createSimpleAlertModal(
                                i18next.t("text.no_domain")
                            );
                        });

                        function collectDomainValues(quackObj) {
                            $("#domainSelectionModalChat")
                                .find(".input-field")
                                .each(function () { 
                                    let quack_id = $(this).data("quack-id");
                                    let domain = $(this).find("select").val();    
                                    quackObj[quack_id].domain = domain;
                                });
                            return quackObj;
                        }

                        function quackPromisesInit(quackMap) {
                            let submitBtn = $("#domainSelectionModalChat").find(
                                ".option-one"
                            );
                            let cancelBtn = $("#domainSelectionModalChat").find(
                                ".option-two"
                            );
    
                            submitBtn.on("click", () => {
                                let quackObj = collectDomainValues(quackMap);

                                Object.values(quackObj).forEach(item => {
                                    const params = { id: item.id, quackMode: quackMode, domain: item.domain };
                                    promise =  instance.chatService.updateChat(params);
                                    quackPromises.push(promise);
                                });

                                toast = quackPromises.length > 1 ? {html: i18next.t("text.quacks_activated")} : {html: i18next.t("text.quack_activated")};       

                                resolvePromiseAll();
                                $("#domainSelectionModalChat").remove();
                            });
                            cancelBtn.on("click", () => {
                                $("#domainSelectionModalChat").remove();
                            });
                        }
                }

                function resolvePromiseAll() {                    

                    $(document).trigger("loader:on");

                    Promise.all(quackPromises)
                    .then(function (response) {
                        $(document).trigger("loader:off");
                        M.toast(toast);
                        instance.triggerChatOverviewLoadByMenu();
                        return "success";
                    })
                    .catch(function (reason) {
                        $(document).trigger("loader:off");
                        let errorMessage = '';

                        if (reason.status === 400) {

                            if (reason.responseText && reason.responseText.includes('insufficient content')) {
                                errorMessage += "One or more chats in your selection are too short to get published. Please add more content to reach at least 100 words.\n<br/>";
                            }
                            
                            if (!errorMessage) {
                                errorMessage = "Invalid article submission. Please review your content.";
                            }
                            
                        } else {
                            errorMessage = "An error occurred while submitting your chat. Please try again. In case the error persists, do not hesitate to contact us via the <a target=\"_blank\" href=\"/contact.html\">contact form</a>.";
                        }
                        
                        instance.utils.createSimpleAlertModal(errorMessage);
                        M.toast({ html: i18next.t("toasts.error") });
                        instance.log.error("update failed because ", reason);
                        return "error";
                    });
                }
            }
        });

        // initialise the button for directly creating a new chat
        $("#createChatButton").on("click", () => {
            $("#newChatFormWrapper").addClass("active");
        });

        $("#closeNewChatFormLinkWrapper > a").on("click", () => {
            $("#newChatFormWrapper").removeClass("active");
            instance._emptyNewChatForm();
        });

        var lngOptions = {
            classes: "edit-language",
        };
        var languageSelect = instance.chatsView.find(
            "#createChatLanguageInput"
        );
        instance.createChatLanguageInput = M.FormSelect.init(
            languageSelect,
            lngOptions
        )[0];

        // init tag module
        function addTag(chipsElement) {
            let textNodeOfNewChip = chipsElement.find(".chip").last()[0]
                .childNodes[0];
            let textOfNewChip = textNodeOfNewChip.data;
            textNodeOfNewChip.data = textOfNewChip
                .trim()
                .replaceAll(/[^a-zA-Z0-9äöüÄÖÜß\.-]+/g, "-");
        }

        var chipsOptions = {
            placeholder: i18next.t("toasts.chips"),
            limit: 5,
            onChipAdd: function (chips) {
                addTag(chips);
            },
        };

        var chipsElement = instance.chatsView.find("#createChatTagsInput");
        instance.newChatChipsInput = M.Chips.init(
            chipsElement,
            chipsOptions
        )[0];

        $("#saveNewChatButton").on("click", (e) => {
            e.preventDefault();
            e.stopImmediatePropagation();
            $(document).trigger("loader:on");

            let chat = {};
            let chatWrapper = instance.chatsView.find("#newChatFormWrapper");
            chat.topic = chatWrapper.find("#createChatTopicInput").val();
            chat.language = chatWrapper.find("#createChatLanguageInput").val();

            let productName = chatWrapper.find("#createChatProductInput").val();
            if (!!productName && productName.length > 0) {
                chat.product = productName;
            }

            let productLink = chatWrapper.find("#createChatLinkInput").val();
            if (!!productLink && productLink.length > 0) {
                chat.link = productLink;
            }

            let tagsData = instance.newChatChipsInput.chipsData;

            if (!!tagsData || Array.isArray(tagsData)) {
                let actualTags = [];
                tagsData.forEach(function (currentTag) {
                    if (!!currentTag.tag) {
                        actualTags.push(currentTag.tag
                            .trim()
                            .replaceAll(/[^a-zA-Z0-9äöüÄÖÜß\.-]+/g, "-"));
                    }
                });

                chat.tags = actualTags;
            }

            instance.chatService
                .createChat(chat)
                .then(async function () {
                    $("#newChatFormWrapper").removeClass("active");
                    M.toast({
                        html: i18next.t("toasts.data_updated"),
                    });
                    instance._emptyNewChatForm();

                    $(document).trigger("loader:off");

                    // Chat has been created so reset any filter and reload the chat view so that the new chat
                    // will be shown at the top of the list.all-and-tv-variables
                    instance.currentChatFilter = undefined;
                    instance.filterChatsInQuackOverview();
                })
                .catch(function (response) {
                    $(document).trigger("loader:off");
                    if (!response || !response.status) {
                        instance.log.error(
                            "Fehler beim Senden der Daten.",
                            response
                        );
                    } else if (response.status == 400) {
                        instance._processFieldErrorsFromServer(response);
                        M.toast({
                            html: i18next.t("toasts.data_invalid"),
                        });
                    } else if (response.status == 403) {
                        M.toast({
                            html: i18next.t("toasts.no_access"),
                        });
                    } else {
                        instance.log.error(
                            "Error during data processing on server.",
                            response.status,
                            response.statusText
                        );
                        M.toast({ html: i18next.t("toasts.error") });
                    }
                });
        });

        //initialise button for showing / hiding the chat-filter
        $("#showQuacksFilterButton").on("click", function () {
            const filterWrapper = $("#quackFilterWrapper");
            let symbol = $(this).find("i");
            if (symbol.text() == "keyboard_arrow_down") {
                symbol.text("keyboard_arrow_up");
                filterWrapper.slideDown("slow");
            } else {
                symbol.text("keyboard_arrow_down");
                filterWrapper.slideUp("slow");
            }
        });

        const quackFilterDateUntilInput = M.Datepicker.init(
            $("#quackFilterDateUntilInput"),
            instance.utils.getStandardDatePickerConfig()
        );

        const filterQuacks = function () {
            let id = $("#quackFilterQuackIdInput").val();
            let topic = $("#quackFilterTopicInput").val();
            let date = quackFilterDateUntilInput[0].date;

            let idNumber = Number(id);
            if (isNaN(idNumber) || idNumber < 0) {
                M.toast({ html: i18next.t("toasts.data_invalid") });
                return;
            }

            let filter = {
                page: 1,
            };

            if (id) filter.chatId = id;
            if (topic) filter.topic = topic;
            if (date) filter.dateUntil = date.toISOString();

            instance.currentChatFilter = filter;
            instance.filterChatsInQuackOverview(filter);
        };

        $("#applyQuackFilterButton").on("click", filterQuacks);
        $("#quackFilterQuackIdInput, #quackFilterTopicInput, #quackFilterDateUntilInput").on(
            "keypress", (e) => {
                if (e.key === "Enter")
                    $("#applyQuackFilterButton").trigger("click");
            }
        );

        $("#clearQuackFilterButton").on("click", function () {
            $("#quackFilterQuackIdInput").val("");
            $("#quackFilterTopicInput").val("");
            $("#quackFilterDateUntilInput").val("");
            quackFilterDateUntilInput[0].date = undefined;
            instance.currentChatFilter = null;
            $("#showQuacksFilterButton").click();
            instance.filterChatsInQuackOverview();
        });
    }

    _processFieldErrorsFromServer(response) {
        let instance = this;
        let errors = JSON.parse(response.responseText).errors;
        let chatWrapper = this.chatsView.find("#newChatFormWrapper");

        for (let item in errors) {
            if (!errors.hasOwnProperty(item)) continue;
            let fieldId = instance.utils.capitaliseFirstLetter(item);

            if (errors[item] == "MISSING") {
                chatWrapper
                    .find("#createChat" + fieldId + "Input")
                    .removeClass("valid");
                chatWrapper
                    .find("#createChat" + fieldId + "Input")
                    .addClass("invalid");
            } else if (errors[item] == "INVALID") {
                chatWrapper
                    .find("#createChat" + fieldId + "Input")
                    .removeClass("valid");
                chatWrapper
                    .find("#createChat" + fieldId + "Input")
                    .addClass("invalid");
            } else {
                instance.log.error(
                    "Encountered unknown validation error type: ",
                    errors[item]
                );
            }
        }
    }

    /**
     * Function to enable external components to fill the view with chats.
     *
     * @param {*} page the page of the search result to show in the chat view
     */
    triggerChatOverviewLoadByMenu(page) {
        var instance = this;

        if (!page || page < 1) page = 1;

        if ($("#quack-overview .collapsible").children("li").length > 0) {
            $("#quack-overview .collapsible").empty();
        }

        if (instance.currentChatFilter) {
            instance.currentChatFilter.page = page;
            instance.filterChatsInQuackOverview(instance.currentChatFilter);
        } else {
            instance.filterChatsInQuackOverview({ page: page });
        }

        // initialise the quack mode select
        var quackModeSelect = $("#quack-target-select");
        M.FormSelect.init(quackModeSelect);
        $("#targetQuacks").localize();
    }

    /**
     * Fetches the desired chats from the server and displays them in the view area.
     *
     * @param {*} filter defines which chats will be shown
     */
    filterChatsInQuackOverview(filter) {
        const instance = this;
        $(document).trigger("loader:on", [
            "",
            "transparent",
            document.getElementById("targetQuacks"),
        ]);

        const quackOverview = $("#quack-overview");
        const saveQuackModeButton = $("#saveQuackMode");
        // remove any previous content
        quackOverview.find(".collapsible").empty();
        quackOverview.find(".no-result-indicator").remove();

        // empty pagination to enable complete redraw
        let paginationList = quackOverview.find(".pagination");
        paginationList.empty();

        instance.chatService.getChatList(filter).then(
            function (payload) {
                $(document).trigger("loader:off");
                if (payload.result.length > 0) {
                    saveQuackModeButton.prop("disabled", false);
                    instance.utils.buildPagination(
                        paginationList,
                        payload.numPages,
                        payload.page,
                        instance.triggerChatOverviewLoadByMenu.bind(instance)
                    );
                    instance.fillChatOverviewHeaders(payload.result);
                    $(document).trigger("loader:off");
                } else {
                    saveQuackModeButton.prop("disabled", true);
                    let noContentHint = $(
                        '<div class="no-result-indicator"><h3 data-i18n="text.no_chats"></h3><a target="_blank" class="prdk-link fs-16" href="/docu/chat.html#add-new-quack" data-i18n="text.quacks_tutorial_link"></a></div>'
                    );
                    quackOverview.append(noContentHint);
                    noContentHint.localize();
                    $(document).trigger("loader:off");
                }
            },
            function (errorReason) {
                $(document).trigger("loader:off");
                if (errorReason.status == 400) {
                    M.toast({
                        html: i18next.t("toasts.data_invalid"),
                    });
                } else {
                    instance.log.error(
                        "ERROR - Could not get chats from server; returned status is " +
                            errorReason.status
                    );
                    saveQuackModeButton.prop("disabled", true);
                    let noContentHint = $(
                        '<div class="no-result-indicator"><h3 data-i18n="text.no_chats"></h3><a class="prdk-link fs-16" href="/docu/chat.html#add-new-quack" data-i18n="text.quacks_tutorial_link"></a></div>'
                    );
                    quackOverview.append(noContentHint);
                    noContentHint.localize();
                }
            }
        );
    }

    _emptyNewChatForm() {
        const instance = this;
        let inputElements = instance.chatsView.find(
            "#expertChatsEditChatForm input, #expertChatsEditChatForm textarea"
        );

        inputElements.each(function (index, element) {
            let formElement = $(element);
            formElement
                .removeClass("valid invalid")
                .css("height", "")
                .val(null);
            M.updateTextFields();
        });

        // re-init language select
        if (instance.createChatLanguageInput) {
            var lngOptions = {
                classes: "edit-language",
            };
            instance.createChatLanguageInput.destroy();
            var languageSelect = instance.chatsView.find(
                "#createChatLanguageInput"
            );
            languageSelect.val("de");
            instance.createChatLanguageInput = M.FormSelect.init(
                languageSelect,
                lngOptions
            )[0];
        }

        // empty chips field
        if (instance.newChatChipsInput) {
            var numberOfChips = instance.newChatChipsInput.chipsData.length;

            for (let i = 0; i < numberOfChips; i++) {
                instance.newChatChipsInput.deleteChip();
            }
        }
    }

    fillChatOverviewHeaders(data) {
        var instance = this;
        var collapsibleHeaders = "";

        for (var chatNr in data) {
            if (!data.hasOwnProperty(chatNr)) {
                continue;
            }

            const chatId = data[chatNr].id;

            var href =
                `<a target="_blank" href="https://www.produck.de/quackref/chat/${chatId}/" >${chatId}</a>`;

            const quackMode = data[chatNr].quackMode;

            var quackModeState = (function() {
                if (quackMode === "PRODUCK") {
                    return {
                        class: "public-produck",
                        href: href,
                        status: "PUBLISHED",
                        domain: ""
                    };
                } else if (quackMode === "EXTERNAL") {
                    return {
                        class: "public-external",
                        href: href,
                        status: quackMode,
                        domain: data[chatNr].domain
                    };
                } else {
                    return {
                        class: "",
                        href: chatId,
                        status: "DRAFT",
                        domain: ""
                    };
                }
            })();

            //TODO User Name einfügen
            collapsibleHeaders +=
                '<li class="js-collapsible-item"><div class="collapsible-header" id="quack-header-' +
                chatId +
                '" data-chatid="' +
                chatId +
                '" ><span class="chat-id">' +
                quackModeState.href +
                "</span>" +
                '    <div class="simple-vertical-content w100">' +
                '<span class="chat-topic current-content">' +
                data[chatNr].topic +
                "</span>" +
                '      <div class="simple-horizontal-content">' +
                '        <div class="chip status-chip ' +
                quackModeState.status +
                '">' +
                i18next.t(
                    "articlesection.status_label_" + quackModeState.status
                ) + quackModeState.domain +
                "</div>" +
                "</div>" +
                "</div>" +
                '<div id="quackHeaderActionButtons' +
                chatId +
                '" class="action-buttons">' +
                '  <ul class="hide simple-horizontal-content">' +
                '    <li><a class="btn-small prdk-btn icon-btn delete-chat-button"><i class="tiny material-icons">delete_forever</i></a></li>' +
                '    <li><a class="btn-small prdk-btn icon-btn edit-title-button"><i class="tiny material-icons">edit</i></a></li>' +
                "  </ul>" +
                '  <a class="actn-btns-more"><i class="material-icons">more_horiz</i></a>' +
                "</div>" +
                '<div class="quack-state ' +
                quackModeState.class +
                '" title="' +
                i18next.t(
                    "expertchatview.publish_quack_check_box_title"
                ) +
                '"><label>' +
                '<input class="quack-state-input filled-in" type="checkbox" /><span></span></label>' +
                '</div></div><div class="collapsible-body" data-chatid="' +
                chatId +
                '" data-expert-id="' +
                data[chatNr].expertId +
                '" data-user-id="' +
                data[chatNr].userId +
                '"></div></li>';
        }

        $("#quack-overview .collapsible").append(collapsibleHeaders);

        for (var chatNr in data) {
            if (!data.hasOwnProperty(chatNr)) {
                continue;
            }

            var collapsibleHeader = $("#quack-header-" + data[chatNr].id);

            collapsibleHeader.on("click", function (ev) {
                function isEmpty(el) {
                    return !$.trim(el.html());
                }

                var collBody = $(this).siblings(".collapsible-body");

                // if body empty fill with corresponding chat details
                if (
                    isEmpty(collBody) &&
                    !$(".quack-state-input").is(ev.target)
                ) {
                    var chatId = $(this).data("chatid");
                    var result = data.find((chat) => chat.id === chatId);
                    instance.fillChatOverviewBody(result);
                }
            });

            instance.editTitle(collapsibleHeader);
        }
    }

    /**
     * Fills the collapsible-body of a single chat entry with data, so mostly all the chat messages as well
     * as some statistics.
     *
     * @param {*} chat the chat to fetch data from the service for
     */
    fillChatOverviewBody(chat) {
        var instance = this;

        instance.buildChatList(chat).then(function (messagesCollapsible) {
            var collBody = $(
                '#quack-overview .collapsible-body[data-chatid="' +
                    chat.id +
                    '"]'
            );

            collBody.append(messagesCollapsible.collapsibleBody);

            setTimeout(() => {
                instance.utils.initMaterializeInContentBlock();
            }, 1000);

            // add button for showing inserting functionality
            let chatMessageInsertModeButton = collBody.find(
                "#chatMessageInsertModeButton"
            );
            chatMessageInsertModeButton.click(function () {
                let wrappers = collBody.find(".quack-new-message-wrapper");
                wrappers.toggleClass("hide");

                // Use the first new message wrapper, which will always be there even for a chat, that has no messages yet,
                // to determine which label the switch-insert-mode-button shoud have. If the wrapper is not visible that
                // means that the insert mode is currently turned off and the button should have a label indicating that a
                // click on it will start the insert-mode. Otherwise if the wrapper is visible that means the insert mode
                // is currently turned on and the button should have a label indicating that a click on it will turn the
                // insert-mode off.
                if ($(wrappers[0]).hasClass("hide")) {
                    $(this).text(i18next.t("text.insert_mode"));
                } else {
                    $(this).text(i18next.t("text.insert_mode_end"));
                }
            });

            // add message inserting functionality
            collBody
                .find(".quack-new-message-wrapper")
                .each(function (index, element) {
                    instance._addInsertMessageBehaviour($(element));
                });

            function initChatUpdate() {
                var chatId = chat.id;
                // collect tags for transmission to server
                var chips = messagesCollapsible.tagsArray;

                // currently there is a bug in MaterializeCSS, see
                // https://github.com/Dogfalo/materialize/issues/6317
                // This bug prevents an empty array given to a Chips-element from being updated
                // correspondingly to the user input. Therefore if the array is empty here, it
                // has to be checked, if there is actually no chip to send to the server
                var tags;
                if (!chips || chips.length < 1) {
                    tags = [];
                    var chipElements = collBody.find(".chip");
                    chipElements.each(function (index, elem) {
                        var textNode = $(elem).contents().first();
                        if (!textNode) return;
                        var tag = textNode.text();
                        if (tag && tag.length > 0) {
                            tags.push(
                                tag
                                    .trim()
                                    .replaceAll(/[^a-zA-Z0-9äöüÄÖÜß\.-]+/g, "-")
                            );
                        }
                    });
                } else {
                    tags = chips.map((obj) => {
                        let newTag = obj.tag
                            .trim()
                            .replaceAll(/[^a-zA-Z0-9äöüÄÖÜß\.-]+/g, "-");
                        return newTag;
                    });
                }

                var params = { id: chatId, tags: tags };
                instance.updateChatEntry(params, {
                    html: i18next.t("toasts.tags_updated"),
                });
            }

            function initLanguageUpdate() {
                var chatId = chat.id;
                var iso = collBody.find(".js-language-select").val();
                var params = { id: chatId, language: iso };
                instance.updateChatEntry(params, {
                    html: i18next.t("toasts.language_updated"),
                });
            }

            function addTag(chipsElement) {
                let textNodeOfNewChip = chipsElement.find(".chip").last()[0]
                    .childNodes[0];
                let textOfNewChip = textNodeOfNewChip.data;
                textNodeOfNewChip.data = textOfNewChip
                    .trim()
                    .replaceAll(/[^a-zA-Z0-9äöüÄÖÜß\.-]+/g, "-");
                initChatUpdate();
            }

            var chipsOptions = {
                data: messagesCollapsible.tagsArray,
                placeholder: i18next.t("toasts.chips"),
                limit: 5,
                onChipAdd: function (chips) {
                    addTag(chips);
                },
                onChipDelete: function () {
                    initChatUpdate();
                },
            };

            var chipsElement = collBody.find(".edit-tags");
            M.Chips.init(chipsElement, chipsOptions); // hint: returns instances

            var languageElement = collBody.find(".edit-language");
            languageElement.on("change", initLanguageUpdate);
            M.FormSelect.init(languageElement, { classes: "edit-language" });
            instance.editChatMessagesEnBloc(collBody);
        });
    }

    /**
     * For a single chat this function builds the detailed view of all messages.
     *
     * @param {Object} chat a single chat object of the list retrieved by the chatService (see function getChatList
     *                      in chatserviceaccess.js)
     */
    buildChatList(chat) {
        var instance = this;

        return instance.chatService.getChatMessages(chat.id).then(
            function (result) {
                return createCollapsible(result);
            },
            function (reason) {
                instance.log.error("no message for reason " + reason);
            }
        );

        function createCollapsible(messageArray) {
            var tagsArray = [];

            // create a data object that is compatible with the Chips-form-element of materializecss
            // it must be an array of the form
            // [{tag: 'tag1'},{tag: 'tag2'}, {tag: 'tag3'}] (or even more than three)
            // the materializecss Chips-form-element will be initialised with this object meaning, that
            // the indluded tags will immediatley been shown and the form-element also adds new tags to
            // the array, whenever the user enters a new tag, or removes tags, whenever the user deletes
            // a tag.
            for (var tag in chat.tags) {
                if (!chat.tags.hasOwnProperty(tag)) {
                    continue;
                }

                tagsArray.push({ tag: chat.tags[tag] });
            }

            let chatText = instance.buildMessagBlock(
                messageArray,
                chat.expertId
            );
            let productLink = instance.utils.convertSourceLink(chat);
            let totalWords = instance.utils.countWordsInQuacks(messageArray);

            var collapsibleBody =
                '<div class="stats-wrapper"><span class="quackity stats">' +
                (!!chat.rating ? chat.rating : "-") +
                '<i class="material-icons" title="' +
                i18next.t("text.rating") +
                '">star_border</i></span>' +
                '<span class="views stats" title="' +
                i18next.t("text.views") +
                '">' +
                (!!chat.views ? chat.views : 0) +
                '<i class="material-icons">visibility</i></span>' +
                '<span class="stats" title="' +
                i18next.t("text.words") +
                '">' +
                totalWords +
                '<i class="material-icons">subject</i></span>' +
                '<span class="timestamp stats" title="' +
                i18next.t("text.date_of_creation") +
                '">' +
                instance.utils.formatDate(chat.startTime) +
                "</span></div>" +
                productLink +
                '<div class="msg-insert-btn-wrapper"><button id="chatMessageInsertModeButton" class="btn waves-effect waves-light">' +
                i18next.t("text.insert_mode") +
                "</button></div>" +
                // message inserting possibility here for inserting at the front and for empty chats
                '<div id ="quackNewMessageWrapperFirst" class="quack-new-message-wrapper hide">' +
                '  <button class="quack-new-message-button-ducky btn waves-effect waves-light">Ducky</button>' +
                '  <button class="quack-new-message-show-button btn disabled"><i class="material-icons">add</i></button>' +
                '  <button class="quack-new-message-button-expert btn waves-effect waves-light">Experte</button>' +
                "</div>" +
                chatText +
                '<div class="edit-tags chips-initial"><input class="input edit-tags-input" placeholder="' +
                i18next.t("toasts.chips") +
                '"></div>' +
                '<div class="input-field language-select-wrapper">' +
                '  <select class="js-language-select edit-language">' +
                '    <option value="de"' +
                (chat.language == "de" ? ' selected="selected"' : "") +
                ">" +
                i18next.t("text.ger") +
                "</option>" +
                '    <option value="en"' +
                (chat.language == "en" ? ' selected="selected"' : "") +
                ">" +
                i18next.t("text.eng") +
                "</option>" +
                "  </select>" +
                "  <label>" +
                i18next.t("text.language") +
                "</label>" +
                "</div>";
            // TODO the following feature is disabled until customers account may be the target of a chat request
            //+ '<div class="btn-wrapper"><a class="contact-btn waves-effect btn" target="_blank" href="https://produck.de/chat.html?cid='+ chat.userId +'" data-position="bottom" data-tooltip="'+i18next.t('text.contact_user_info')+'">'+i18next.t('text.contact_user')+'</span></div>';

            // Note! The behaviour of the new-message-elements is added in "fillChatOverviewBody" since before the JQuery-append there
            // all the HTML stuff is just  a huge string. And no events can be bound to parts of that string. -> refactor

            return { collapsibleBody, tagsArray };
        }
    }

    // enables to do adjustments to the quack title
    editTitle(collapsibleHeaderElement) {
        var instance = this;

        let textareaClass = "chat-title-field"; //not there at the moment of initialisation
        let buttonToInitInput =
            collapsibleHeaderElement.find(".edit-title-button");
        let spanToBeUpdated = collapsibleHeaderElement.find("span.chat-topic");
        let chatId = collapsibleHeaderElement.data("chatid");
        let actionButtons = $("#quackHeaderActionButtons" + chatId);
        let deleteBtn =
            '<div class="edit-item delete"><button type="delete" class="edit-button delete-chat-button waves-effect waves-light"><i class="material-icons edit-icon">delete_forever</i></button></div>';

        instance.editQuackEntries(
            collapsibleHeaderElement,
            buttonToInitInput,
            spanToBeUpdated,
            textareaClass,
            deleteBtn,
            actionButtons
        );
    }

    // calls initFunction for a set of message blocks to prepare editMode
    editChatMessagesEnBloc(collapsibleBodyElement) {
        var instance = this;

        let editMessageItem = collapsibleBodyElement
            .find(".dialogue-summary")
            .map(function () {
                return this;
            })
            .get();

        editMessageItem.forEach(function (messageItem) {
            instance._initMessageItemsforEditMode(messageItem);
        });
    }

    // calls initFunction for one single message Block to prepare editMode
    editChatMessageSingle(messageItem) {
        var instance = this;
        instance._initMessageItemsforEditMode(messageItem);
    }

    // enables edit Mode for quack messages
    _initMessageItemsforEditMode(messageItem) {
        var instance = this;

        let textareaClass = "chat-message-field";
        let editAreaWrapper = $(messageItem).find(".summary");
        let buttonToInitInput = $(messageItem).find(".edit-item-button");
        let spanToBeUpdated = $(messageItem).find(".question-hyperlink");
        let messageId = editAreaWrapper.data("messageid");
        let actionButtons = $("#messageActionButtons" + messageId);

        let deleteBtn =
            '<div class="edit-item delete"><button type="delete" class="edit-button delete-item-button waves-effect waves-light"><i class="material-icons edit-icon">delete_forever</i></button></div>';

        let contentRetrievalFunction = function (callback) {
            instance.chatService
                .getChatMessageForEditing(messageId)
                .then(function (message) {
                    callback(message.text);
                });
        };

        instance.editQuackEntries(
            editAreaWrapper,
            buttonToInitInput,
            spanToBeUpdated,
            textareaClass,
            deleteBtn,
            actionButtons,
            contentRetrievalFunction
        );
    }

    // contains all edit event listeners and required functions
    editQuackEntries(
        editAreaWrapper,
        buttonToInitInput,
        spanToBeUpdated,
        textareaClass,
        deleteButtonElem,
        actionButtons,
        contentRetrievalFunction
    ) {
        var instance = this;

        async function saveNewField(textarea, parentElem) {
            var newContent = textarea.val();
            let linkifiedContent = await newContent.linkify();
            let content = () => {
                parentElem.find(spanToBeUpdated).html(linkifiedContent);
            };

            if (editAreaWrapper.hasClass("collapsible-header")) {
                let quackId = parentElem.data("chatid");
                let params = { id: quackId, topic: newContent };

                instance
                    .updateChatEntry(params, {
                        html: i18next.t("toasts.title_updated"),
                    })
                    .then(function () {
                        content();
                    });
            } else if (editAreaWrapper.hasClass("summary")) {
                let messageId = parentElem.data("messageid");
                instance
                    .updateChatMessage(newContent, messageId, {
                        html: i18next.t("toasts.message_updated"),
                    })
                    .then(function () {
                        content();
                        setTimeout(() => {
                            instance.utils.initMaterializeInContentBlock();
                        }, 1000);
                    });
            } else if (editAreaWrapper.hasClass("panel_block")) {
                var uid = await instance.getUidFromCookies();
                var parameterName = parentElem
                    .find(spanToBeUpdated)
                    .data("param");
                let params = { ["id"]: uid, [parameterName]: newContent };

                return instance.identityService.saveUserData(params).then(
                    function (result) {
                        $(document).trigger("loader:off");
                        instance.log.debug("data retrieval succeeded", result);
                        content();
                        M.toast({
                            html: i18next.t("toasts.data_updated"),
                        });
                    },
                    function (error) {
                        $(document).trigger("loader:off");
                        var responseTxt = "Keine";

                        if (error.response) {
                            responseTxt = error.response
                                .replace(/_/g, " ")
                                .replace("]", "")
                                .replace("[", "");
                        }

                        var textBody =
                            i18next.t("profilepage.data_update_failed") +
                            responseTxt +
                            " (" +
                            error.status +
                            ")";
                        instance.utils.createSimpleAlertModal(textBody);
                        instance.log.error(
                            "editQuackEntries - saving user data failed.",
                            error.status,
                            error.response
                        );
                    }
                );
            }
        }

        function closeAllEditFields(form, editableArea) {
            form.remove();
            editableArea.removeClass("field-editable");
        }

        //show actions buttons
        actionButtons.on("mouseenter", function () {
            $(this).find("ul").removeClass("hide");
        });

        //hide action buttons
        actionButtons.on("mouseleave", function () {
            $(this).find("ul").addClass("hide");
        });

        /**
         * Fixe the issue that sometimes clicking on an action element inside a collapsible item causes
         * the collapsible item to collapse unintentionally. In order for this method to work each
         * item (<li>) of the collapsible must be marked with the class 'js-collapsible-item'.
         *
         * @param {*} referenceElem the element an event has been triggered by that should not change the collpsible-state
         */
        function stopCollapsible(referenceElem) {
            // search for the container that encloses all particular collapsible elements
            var collapsibleContainer = $(referenceElem).closest(".collapsible");
            // determine the index of the collapsible item the reference element is enclosed by
            var collapsibleIndex = $(referenceElem)
                .closest("li.js-collapsible-item")
                .index();
            var collapsibleInstance =
                M.Collapsible.getInstance(collapsibleContainer);
            collapsibleInstance.close(collapsibleIndex);
        }

        $(".collapsible-header i, .collapsible-header .edit-button, .collapsible-header .edit-title-button, .collapsible-header .chat-title-field").on(
            "click",
            function () {
                stopCollapsible(this);
            }
        );

        // turn chat-title element into form, which can be edited and saved afterwards
        $(buttonToInitInput).on("click", function (e) {
            e.stopImmediatePropagation();

            // TODO Hide Action Buttons

            // close all active editable fields
            if ($("#quack-overview .edit-form").length > -1)
                closeAllEditFields(
                    $("#quack-overview .edit-form"),
                    $("#quack-overview .field-editable")
                );

            if (
                !editAreaWrapper.hasClass("chat-message-field") &&
                !editAreaWrapper.hasClass("edit-button") &&
                !editAreaWrapper.hasClass("edit-icon")
            ) {
                if (!editAreaWrapper.hasClass("field-editable")) {
                    let updateContentCallback = function (content) {
                        var formWrapper =
                            `<form class="edit-form form-${textareaClass}" action="#">` +
                            `  <textarea class="${textareaClass}" name="summary" type="text"></textarea>` +
                            '      <div class="js-visual-input-area in-place-edit-field-visual hide"></div>' +
                            '  <div class="save-options">' +
                            '    <div class="edit-item update">' +
                            '      <button type="submit" class="btn prdk-btn icon-btn edit-button save-item-button waves-effect waves-light"><i class="material-icons edit-icon">check</i></button>' +
                            "    </div>" +
                            '    <div class="edit-item cancel">' +
                            '      <button type="cancel" class="btn prdk-btn icon-btn edit-button cancel-item-button waves-effect waves-light"><i class="material-icons edit-icon">cancel</i></button>' +
                            "    </div>" +
                            "  </div>" +
                            "</form>";

                        $(formWrapper).insertAfter(
                            editAreaWrapper.find(".current-content")
                        );
                        editAreaWrapper.addClass("field-editable");
                        var textArea = editAreaWrapper.find("textarea");
                        textArea.text(content);
                        initSaveAndCancelButton(textArea, editAreaWrapper);
                        instance.utils.adjustTextarea(textArea, 1500);
                        const collBody = editAreaWrapper.closest(".collapsible-body");
                        let languageSelect = collBody.find(".js-language-select");
                        const chatId = collBody.data("chatid");
                        instance.utils.addWswgBar($(".chat-message-field"), {"htmlEle": languageSelect, "postId": "chat"+chatId});
                        setTimeout(function () {
                            textArea.focus();
                        }, 250);
                    };

                    if (contentRetrievalFunction) {
                        contentRetrievalFunction(updateContentCallback);
                    } else {
                        let content = spanToBeUpdated.text();
                        updateContentCallback(content);
                    }
                }
            }

            // Close the action buttons bar to give feedback to the user and to remove irritating buttons
            // over new elements.
            $(this).closest(".action-buttons ul").addClass("hide");
        });

        // init delete messages and unbind former clicks
        actionButtons.find(".delete-item-button").on("click", function (e) {
            e.stopImmediatePropagation();
            var messageId = editAreaWrapper.data("messageid");
            var messageContainer = $(this).closest(".dialogue-summary");
            messageContainer.css({
                "background-color": "rgba(178,0,35,.6) !important",
            });

            var headline = i18next.t("text.message_delete");
            var text = i18next.t("text.message_history_info");
            var optionOne = i18next.t("text.yes_delete");
            var optionTwo = i18next.t("general.no");

            //creates a suitable modal
            instance.utils.createModal(
                $("body"),
                headline,
                text,
                optionOne,
                optionTwo,
                "deleteItemModal"
            );

            // call delete function if delete gets confirmed
            $("#deleteItemModal .modal-close.option-one").on(
                "click",
                function () {
                    instance.chatService.deleteChatMessage(messageId).then(
                        function () {
                            messageContainer.remove();
                            $("#quackNewMessageWrapper" + messageId).remove();
                        },
                        function (error) {
                            instance.log.error("Deletion failed: ", error);
                        }
                    );
                    $("#deleteItemModal").remove();
                }
            );

            // reset all conditions if delete gets declined
            $("#deleteItemModal .modal-close.option-two").on(
                "click",
                function () {
                    messageContainer.css({ background: "" });
                    $("#deleteItemModal").remove();
                }
            );
        });

        // init delete chats and unbind former clicks
        actionButtons.find(".delete-chat-button").on("click", function (e) {
            e.stopImmediatePropagation();
            // editAreaWrapper cannot be used here because the value of it will change as soon as
            // editQuackEntries has been called a second time whenever the user clicks some chat
            var chatContainer = $(this).closest(".collapsible-header");
            var chatId = chatContainer.data("chatid");
            chatContainer.css({
                "background-color": "rgba(178,0,35,.6) !important",
            });

            var headline = i18next.t("text.chat_delete");
            var text = i18next.t("text.chat_deletion_confirmation");
            var optionOne = i18next.t("text.yes_delete");
            var optionTwo = i18next.t("general.no");

            //creates a suitable modal
            instance.utils.createModal(
                $("body"),
                headline,
                text,
                optionOne,
                optionTwo,
                "deleteChatModal"
            );

            // call delete function if delete gets confirmed
            $("#deleteChatModal .modal-close.option-one").on(
                "click",
                function () {
                    instance.chatService.deleteChat(chatId).then(function () {
                        chatContainer.remove();
                    });
                    $("#deleteChatModal").remove();
                }
            );

            // reset all conditions if delete gets declined
            $("#deleteChatModal .modal-close.option-two").on(
                "click",
                function () {
                    chatContainer.css({ background: "" });
                    $("#deleteChatModal").remove();
                }
            );
        });

        // changes will be read in front-end and sent to backend
        function initSaveAndCancelButton(
            currentTextarea,
            currentEditAreaWrapper
        ) {
            var saveButton = currentEditAreaWrapper.find(".save-item-button");
            var cancelButton = currentEditAreaWrapper.find(
                ".cancel-item-button"
            );
            var close = function () {
                closeAllEditFields(
                    currentTextarea.parent(".edit-form"),
                    currentEditAreaWrapper
                );
            };

            $(saveButton).on("click", function (e) {
                e.preventDefault();
                e.stopImmediatePropagation();
                $(document).trigger("loader:on");
                saveNewField(currentTextarea, currentEditAreaWrapper);
                close();
            });

            // cancels the process
            $(cancelButton).on("click", function (e) {
                e.preventDefault();
                e.stopImmediatePropagation();
                close();
            });
        }
    }

    /**
     * Performs an update request to the chat service that is intended to update the parts
     * of a chat object that are included in 'params'.
     *
     * @param {*} params the chat data, that should be updated as JSON, for example { "id": 1, "topic": "new topic"}
     * @param {*} tostParams a parameter object for the materializecss function M.toast, for example {html: 'Hurray'}
     */
    updateChatEntry(params, toastParams) {
        var instance = this;

        return instance.chatService.updateChat(params).then(
            function () {
                $(document).trigger("loader:off");
                if (toastParams) {
                    M.toast(toastParams);
                }

                return "success";
            },
            function (reason) {
                $(document).trigger("loader:off");
                M.toast({ html: i18next.t("toasts.error") });
                instance.log.error("update failed because ", reason);
                return "error";
            }
        );
    }

    updateChatMessage(text, messageId, toastParams) {
        var instance = this;

        return instance.chatService.updateChatMessage(messageId, text).then(
            function () {
                $(document).trigger("loader:off");
                if (toastParams) {
                    M.toast(toastParams);
                }
                return "success";
            },
            function (reason) {
                $(document).trigger("loader:off");
                M.toast({ html: i18next.t("toasts.error") });
                instance.log.error("update failed because ", reason);
            }
        );
    }

    buildMessagBlock(messageArray, expertId) {
        const instance = this;
        let messages = "";

        for (var messageId in messageArray) {
            if (!messageArray.hasOwnProperty(messageId)) {
                continue;
            }

            messages += instance._buildquackChatWrapper(
                messageArray[messageId],
                expertId
            );
        }
        return messages;
    }

    /**
     * Buidls the html-code of a single message in the quack-edit-view.
     *
     * @param {*} message the message that will be displayed
     * @param {*} expertId the expert of the chat
     * @param {*} showInsert if true the insert-message-elements will be shown, otherwise they are hidden (default)
     */
    _buildquackChatWrapper(message, expertId, showInsert) {
        const instance = this;
        instance.utils.linkifyText();
        let messageSenderIdentifyingClass =
            message.senderId === expertId ? "right-duck" : "left-duck";
        let author =
            message.senderId === expertId
                ? i18next.t("text.xpert")
                : "Ducky";
        let messageText = instance.utils
            .htmlDecode(message.text)
            .linkify('', expertId); //htmlDecode should be removed to prevent script injection

        var quackChatWrapper =
            '<div class="dialogue-summary narrow ' +
            messageSenderIdentifyingClass +
            '">' +
            '  <div id="messageActionButtons' +
            message.id +
            '" class="action-buttons">' +
            '    <ul class="hide simple-horizontal-content">' +
            '      <li><a class="btn-small prdk-btn icon-btn delete-item-button"><i class="tiny material-icons">delete</i></a></li>' +
            '      <li><a class="btn-small prdk-btn icon-btn edit-item-button"><i class="tiny material-icons">edit</i></a></li>' +
            "    </ul>" +
            '    <a class="actn-btns-more"><i class="material-icons">more_horiz</i></a>' +
            "  </div>" +
            '  <div class="summary" data-messageid="' +
            message.id +
            '">' +
            '    <div class="current-content">' +
            '      <div class="question-hyperlink">' +
            messageText +
            "</div>" +
            "    </div>" +
            "  </div>" +
            '  <div class="author"><span class="author-name">' +
            author +
            "</span></div>" +
            "</div>" +
            '<div id ="quackNewMessageWrapper' +
            message.id +
            '" class="quack-new-message-wrapper' +
            (showInsert ? "" : " hide") +
            '" data-message-id="' +
            message.id +
            '">' +
            '  <button class="quack-new-message-button-ducky btn waves-effect waves-light">Ducky</button>' +
            '  <button class="quack-new-message-show-button btn disabled"><i class="material-icons">add</i></button>' +
            '  <button class="quack-new-message-button-expert btn waves-effect waves-light">Experte</button>' +
            "</div>";

        return quackChatWrapper;
    }

    _addInsertMessageBehaviour(quackNewMessageWrapper, messageId) {
        const instance = this;

        if (!messageId) {
            messageId = quackNewMessageWrapper.attr("data-message-id");
        }

        // If no message ID is given at all the behaviour will insert messages at the beginning of the chat.

        let addDuckyMessageButton = quackNewMessageWrapper.find(
            ".quack-new-message-button-ducky"
        );
        let addExpertMessageButton = quackNewMessageWrapper.find(
            ".quack-new-message-button-expert"
        );

        addDuckyMessageButton.off("click").click(function () {
            instance.addInsertMessageBlock(
                quackNewMessageWrapper,
                0,
                messageId
            );
        });

        addExpertMessageButton.off("click").click(function () {
            instance.addInsertMessageBlock(
                quackNewMessageWrapper,
                1,
                messageId
            );
        });
    }

    /**
     * Adds a block to the quack edit view for creating a new message.
     *
     * @param {*} insertTarget element after that the insert message block will be inserted into the DOM
     * @param {*} type 0 = customer/ducky and 1 = expert
     * @param {number} messageId the id of the message after that the block will be inserted, i.e. the preceding message
     */
    async addInsertMessageBlock(insertTarget, type, messageId) {
        const instance = this;
        messageId = Number(messageId);

        // If messageId is given but is not a number something is wrong with the calling code. By default if no
        // messageId is given, the created insert-message-block will insert new messages at the beginning of the chat.
        if ((messageId && isNaN(messageId)) || messageId < 0) {
            instance.log.error(
                "addInsertMessageBlock - no valid message ID given; instead: ",
                messageId
            );
            messageId = undefined;
        }

        let name = type == 0 ? "Ducky" : "Expert";
        let bubbleClass = type == 0 ? "left-duck" : "right-duck";
        let identifierString = messageId ? String(messageId) : "First";

        $("#quackNewMessageWrapper" + identifierString).addClass("hide");

        let editSection = $(
            '<div class="dialogue-summary narrow ' +
                bubbleClass +
                '">' +
                '  <div class="summary field-editable" ' +
                (messageId ? 'data-messageid="' + messageId + '"' : "") +
                ">" +
                '    <form class="edit-form form-chat-message-field" action="#">' +
                '      <textarea id="newChatMessageField' +
                identifierString +
                '" class="chat-message-field wswg-enabled" name="summary" type="text" style="height:73px;overflow-y:hidden;"></textarea>' +
                '      <div class="js-visual-input-area in-place-edit-field-visual hide"></div>' +
                '      <div class="save-options">' +
                '        <div class="edit-item update"><button type="submit" class="btn prdk-btn icon-btn edit-button save-new-message-button waves-effect waves-light"><i class="material-icons edit-icon">check</i></button></div>' +
                '        <div class="edit-item cancel"><button type="cancel" class="btn prdk-btn icon-btn edit-button cancel-insert-message-button waves-effect waves-light"><i class="material-icons edit-icon">cancel</i></button></div>' +
                "      </div>" +
                "    </form>" +
                "  </div>" +
                '  <div class="author"><span class="author-name">' +
                name +
                "</span></div>" +
                "</div>"
        );

        editSection.insertAfter(insertTarget);

        const messageArea = editSection.find(
            "#newChatMessageField" + identifierString
        );
        // Set the focus on the new textarea. The timeout-construct is necessary because if focus() is called
        // directly any event handling, that initially called addInsertMessageBlock(), might set the focus on
        // the element that triggered the event handling. Furthermore it probably has to be ensured that the
        // focus is set after the textarea has actually be added to the DOM.
        setTimeout(() => {
            messageArea.focus();
        }, 0);

        editSection.find(".cancel-insert-message-button").on('click', function (e) {
            e.preventDefault();
            $("#quackNewMessageWrapper" + identifierString).removeClass("hide");
            editSection.remove();
        });

        const chatContainer = editSection.closest(".collapsible-body");
        const chatId = chatContainer.attr("data-chatid");

        editSection.find(".save-new-message-button").on('click', async function (e) {
            e.preventDefault();

            const expertId = chatContainer.attr("data-expert-id");
            const sender =
                type == 0 ? chatContainer.attr("data-user-id") : expertId;

            let text = messageArea.val();
            if (!text || text.length == 0) {
                M.toast({ html: i18next.t("toasts.data_missing") });
                return;
            }

            try {
                // send new message to server (chatId, senderId, text, precedingMessage)
                let newMessageId = await instance.chatService.insertTextMessage(
                    chatId,
                    sender,
                    text,
                    messageId
                );

                let message = {};
                message.id = newMessageId;
                message.chatId = chatId;
                message.senderId = sender;
                message.text = text;

                let newWrapper = $(
                    instance._buildquackChatWrapper(message, expertId, true)
                );

                // newWrapper is now acctually a set of two elements. The second one is the new-message-wrapper
                // which shall get the insert-message-behaviour. $.find() cannot be used here since it only
                // finds elements fullfilling its selector, that are children of the nodes in the set. The
                // alternative would be using addBack, which would be less error prone to future changes of
                // _buildquackChatWrapper() but also not very elegant. And assuming changes to _buildquackChatWrapper
                // would anyway makes changes necessary here its not worth the effort.
                instance._addInsertMessageBehaviour(
                    $(newWrapper[1]),
                    newMessageId
                );

                editSection.replaceWith(newWrapper);
                $("#quackNewMessageWrapper" + identifierString).removeClass(
                    "hide"
                );

                M.toast({
                    html: i18next.t("toasts.message_updated"),
                });
                instance.editChatMessageSingle(newWrapper);
            } catch (error) {
                instance.log.error("Could not insert message.", error);
                M.toast({
                    html: i18next.t("toasts.data_update_failed"),
                });
            }
        });

        let languageSelect = chatContainer.find(".js-language-select");
        instance.utils.addWswgBar(editSection.find("textarea.wswg-enabled"), {"htmlEle": languageSelect, "postId": "chat"+chatId});
        instance.utils.adjustTextarea(messageArea, 1500);
    }
}
